import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components';
import ThemeProvider from './providers/ThemeProvider';
import styled from 'styled-components';
import Header from './components/header/index';
import Footer from './components/footer/index';
import Home from './components/home/index';
import About from './components/about/index';
import Contact from './components/contact/index';
import { lightTheme } from './theme';
import Gallery from './components/gallery';
import Programs from './components/programs';
import Educational from './components/educational';
import Donations from './components/donations';
import Admin from './components/admin';
import CalendarEvents from './components/calendar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: ${(props) => props.theme.fonts};
    color: ${(props) => props.theme.text}; // Default text color
    background-color: ${(props) =>
      props.theme.background}; // Default background color
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0; /* Safari and Chrome */
    height: 0;
  }
`;

function App() {
  return (
    <ThemeProvider>
      <StyledThemeProvider theme={lightTheme}>
        <GlobalStyle />
        <AppContainer>
          <Router>
            <Header />
            <Container>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/programs" element={<Programs />} />
                <Route path="/educational" element={<Educational />} />
                <Route path="/donations" element={<Donations />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/calendar" element={<CalendarEvents />} />
              </Routes>
            </Container>
            <Footer />
          </Router>
        </AppContainer>
      </StyledThemeProvider>
    </ThemeProvider>
  );
}

export default App;
