import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/vector_ikonkar.svg';
import { scaleAnimation } from '../../utils/animations';

const Nav = styled.nav`
  background: ${(props) => props.theme.primary};
  max-height: 60px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const NavLinks = styled.div`
  a {
    color: ${(props) => props.theme.text};
    margin: 10px;
    text-decoration: none;
    font-size: 1.2rem;
    max-width: 2vw;
    &:hover {
      color: ${(props) => props.theme.nav};
      transition: 0.3s;
    }
  }
`;
const NavLinksTitles = styled(NavLinks)`
  a {
    font-size: 1.2rem;
    &:hover {
      text-shadow: 0 2px 12px rgba(255, 102, 0, 0.2);
      font-size: 1.3rem;
    }
  }
`;

const StyledLogo = styled(Logo)`
  width: 50px;
  height: 50px;
  color: ${(props) => props.theme.text};
  animation: ${scaleAnimation} 2s ease-in-out infinite;
`;

function Header() {
  return (
    <Nav>
      <StyledLogo />
      <NavLinksTitles>
        <Link to="/">Home</Link>
        <Link to="/gallery">Gallery</Link>
        <Link to="/educational">Educational</Link>
        <Link to="/about">About</Link>
        <Link to="/donations">Donations</Link>
      </NavLinksTitles>
      <NavLinks>
        <Link to="/calendar" title="Events">
          <FontAwesomeIcon icon="calendar-alt" />
        </Link>
        <Link to="/programs" title="Permanent Programs">
          <FontAwesomeIcon icon="earth-americas" />
        </Link>
        <Link to="/contact" title="Contact-us">
          <FontAwesomeIcon icon="address-card" />
        </Link>
        <Link to="/admin" title="Admin">
          <FontAwesomeIcon icon="user" />
        </Link>
      </NavLinks>
    </Nav>
  );
}

export default Header;
