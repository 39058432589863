import React, { useState } from 'react';
import styled from 'styled-components';

import { getFirestore, collection, addDoc } from 'firebase/firestore';
import app, { auth } from '../../config/firebase'; // Import your Firebase configuration file
import { signInWithEmailAndPassword } from 'firebase/auth';

const HomeContainer = styled.div`
  width: 100%;
  height: 80vh;
  max-height: calc(100vh - 120px);
  color: black;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  height: 1.5rem;
  width: 20rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  &:focus {
    outline: none;
    border: 1px solid #007bff;
  }
`;

const LoginBox = styled.div`
  padding: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
`;

const Button = styled.button`
  width: 10rem;
  height: 3rem;
  margin: 1rem;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.primary};
  color: white;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3rem;
  align-self: center
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.nav};
  }
`;

const Div = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.text`
  color: ${(props) => props.theme.primary};
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1rem;
  align-slef: center;
`;

function Admin() {
  const db = getFirestore(app); // Initialize Firestore
  const [eventData, setEventData] = useState({
    title: '',
    date: '',
    timefrom: '',
    timeto: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(db, 'events'), eventData);
      setEventData({
        title: '',
        date: '',
        timefrom: '',
        timeto: '',
        description: '',
      });
      alert('Event added successfully!');
    } catch (error) {
      console.error('Error adding event: ', error);
    } finally {
      setLoading(false);
    }
  };
  const handleLogin = async () => {
    setLoading(true);
    try {
      if (!email) {
        alert('Please enter your email.');
        return;
      }

      if (!password) {
        alert('Please enter your password.');
        return;
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      setUser(user);
      if (!user) {
        alert('Login failed. Please check your email and password.');
        alert('Event added successfully!');
      }
    } catch (error) {
      alert('Login failed. Please check your email and password.');
    } finally {
      setLoading(false);
    }
  };

  function logout() {
    auth.signOut();
    setUser(null);
  }

  return (
    <HomeContainer>
      <Title style={{ fontSize: '2rem' }}>
        {user ? 'Add New Event' : 'Please login'}
      </Title>
      {user ? (
        <form
          onSubmit={handleSubmit}
          style={{
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            padding: '60px',
          }}
        >
          <Div style={{ display: 'flex', flexDirection: 'row' }}>
            <Title>Event Name:</Title>
            <Input
              type="text"
              name="title"
              value={eventData.title}
              onChange={handleChange}
              required
            />
          </Div>
          <Div>
            <Title>Date:</Title>
            <Input
              type="date"
              name="date" // Date input field
              value={eventData.date}
              onChange={handleChange}
              required
            />
          </Div>
          <Div>
            <Title>Time:</Title>
            <Input
              style={{ width: '9rem', margin: '0.5rem' }}
              type="time"
              name="timefrom"
              value={eventData.timefrom}
              onChange={handleChange}
            />
            <Input
              style={{ width: '9rem', margin: '0.5rem 0.5rem 0 -4rem' }}
              type="time"
              name="timeto"
              value={eventData.timeto}
              onChange={handleChange}
            />
          </Div>
          <Div>
            <Title>Description:</Title>
            <Input
              name="description"
              value={eventData.description}
              onChange={handleChange}
              required
            />
          </Div>

          <Button type="submit" disabled={loading}>
            {loading ? 'Adding Event...' : 'Add Event'}
          </Button>
          <Button onClick={logout}>Logout</Button>
        </form>
      ) : (
        <LoginBox>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button onClick={handleLogin} disabled={loading}>
            {loading ? 'Logging In...' : 'Login'}
          </Button>
        </LoginBox>
      )}
    </HomeContainer>
  );
}

export default Admin;
