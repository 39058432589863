// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDHGyp2eXSsLCMGSaM4-zdfNrPN3URI7rE',
  authDomain: 'blue-mountain-gurudwara-b7d0c.firebaseapp.com',
  projectId: 'blue-mountain-gurudwara-b7d0c',
  storageBucket: 'blue-mountain-gurudwara-b7d0c.firebasestorage.app',
  messagingSenderId: '893295961214',
  appId: '1:893295961214:web:fcfb9629731ce7449ce42f',
  measurementId: 'G-FGLVVNC5XW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
export const auth = getAuth(app);

export default app;
