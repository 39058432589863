import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
//import timeGridPlugin from "@fullcalendar/timegrid";

import { collection, getDocs, getFirestore } from 'firebase/firestore';
import app from '../../config/firebase';

const db = getFirestore(app); // Initialize Firestore

const HomeContainer = styled.div`
  width: 100%;
  height: 85vh;
  flex-direction: column;
  display: flex;
  color: ${(props) => props.theme.primary};
`;

function CalendarEvents() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, 'events'));
      const eventList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventList);
    };

    fetchEvents();
  }, []);
  return (
    <HomeContainer>
      <FullCalendar
        defaultView="dayGridMonth"
        header={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        themeSystem="Simplex"
        plugins={[dayGridPlugin]}
        events={events}
        height={'100%'}
      />
    </HomeContainer>
  );
}

export default React.memo(CalendarEvents);
